let hamburger = document.querySelector(".hamburger"),
  navListContainer = document.querySelector(".nav__list-container"),
  header = document.getElementById("nav");
if (hamburger) {
  hamburger.onclick = () => {
    hamburger.classList.toggle("is-active"),
      navListContainer.classList.toggle("nav__list-container--open"),
      header.classList.toggle("open");
  };
}

let tabbed = document.querySelectorAll(".tabbed");
if (tabbed) {
  tabbed.forEach((t) => {
    let tabs = t.querySelectorAll(".tab");
    let tabControlls = t.querySelectorAll(".tab-switch button");
    if (tabs.length === tabControlls.length) {
      tabControlls.forEach((c) => {
        c.addEventListener("click", () => {
          if (!c.classList.contains("active")) {
            tabs.forEach((tb) => tb.classList.toggle("active"));
            tabControlls.forEach((tb) => tb.classList.toggle("active"));
          }
        });
      });
    }
  });
}

const seasonalSection = document.getElementById("seasonal")
const discoverButton = document.getElementById("discoverButton")
discoverButton.addEventListener("click", ()=>{
  seasonalSection.scrollIntoView({ behavior: "smooth" });
})


function sendMessage(e) {
  var post_url = "forms/submit.php";
  var request_method = "POST";
  var form_data = $("#contact-form").serialize();
  const input = document.querySelector("#phone");
  data = window.intlTelInputGlobals.getInstance(input).selectedCountryData;
  form_data = `${form_data}&country=${data.iso2}&code=${data.dialCode}`;

  $.ajax({
    url: post_url,
    type: request_method,
    data: form_data,
    statusCode: {
      400: function (responseObject, textStatus, jqXHR) {
        $("#submit-errors").text("Sending failed. Please try again!");
        $("#submit-errors").addClass("visible");
      },
    },
  }).done(function (response) {
    //
    $("input, textarea, button, select", "#contact-form").prop(
      "disabled",
      true
    );
    $("#submit-errors").text("Message sent. Thank you!");

    $("#submit-errors").addClass("visible");
  });

  return false;
}
